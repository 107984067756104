<template>
  <v-container fluid class="text-center pt-15">
    <v-row class="mt-5 mx-5" justify="center">
      <v-col cols="12" lg="4">
        <div class="font-weight-black">
          Alcance 1
          <v-btn x-small icon class="tooltipOne pb-1" @click="$refs.tooltipGuide.start(0)">
            <v-icon small>mdi-information-outline</v-icon>
          </v-btn>
        </div>
        <!--        <v-chip color="#FFDE59" class="text&#45;&#45;black">Hola</v-chip>-->
        <div class="my-2 subtitle-2">
          Completado ({{ catalogues.fuentes_emisiones.completado1 + '/' + catalogues.fuentes_emisiones.alcance1.length }})
        </div>
        <v-sheet elevation="5" class="mt-2 v-step-1 alcanceuno" rounded>
          <!--          color="secondary" class="text-normal black&#45;&#45;text mx-1 d-none d-md-flex"-->
          <v-chip-group
            active-class="elegido3 white--text"
            v-if="catalogues.fuentes_emisiones.alcance1.length"
            v-model="scope.alcance1"
            class="mx-3 horizontal-scroll transparent"
            show-arrows
            light
          >
            <v-chip
              v-for="(item, idx) in catalogues.fuentes_emisiones.alcance1"
              :ref="'emissionSource-' + item.id"
              :key="idx"
              @click="setEmissionSource(item.id)"
              >{{ item.nombre }}</v-chip
            >
          </v-chip-group>
          <div v-else class="py-3">No se obtuvieron fuentes de emisión</div>
        </v-sheet>
      </v-col>
      <v-col cols="12" lg="4">
        <div class="font-weight-black">
          Alcance 2
          <v-btn icon class="pb-1 tooltipTwo" @click="$refs.tooltipGuide.start(1)" x-small>
            <v-icon small>mdi-information-outline</v-icon>
          </v-btn>
        </div>
        <div class="my-2 subtitle-2">
          Completado ({{ catalogues.fuentes_emisiones.completado2 + '/' + catalogues.fuentes_emisiones.alcance2.length }})
        </div>
        <v-sheet elevation="5" class="mt-2" rounded>
          <v-chip-group
            v-if="catalogues.fuentes_emisiones.alcance2.length"
            v-model="scope.alcance2"
            class="mx-3"
            active-class="elegido3 white--text"
            show-arrows
          >
            <v-chip
              v-for="(item, idx) in catalogues.fuentes_emisiones.alcance2"
              :ref="'emissionSource-' + item.id"
              :key="idx"
              @click="setEmissionSource(item.id)"
              >{{ item.nombre }}</v-chip
            >
          </v-chip-group>
          <div v-else class="py-3">No se obtuvieron fuentes de emisión</div>
        </v-sheet>
      </v-col>
      <v-col cols="12" lg="4">
        <div class="font-weight-black downloadreport">
          Alcance 3
          <v-btn icon class="pt-0 pb-1 tooltipThree" @click="$refs.tooltipGuide.start(2)" x-small>
            <v-icon small>mdi-information-outline</v-icon>
          </v-btn>
        </div>
        <div class="my-2 subtitle-2">
          Completado ({{ catalogues.fuentes_emisiones.completado3 + '/' + catalogues.fuentes_emisiones.alcance3.length }})
        </div>
        <v-sheet elevation="5" class="mt-2" rounded>
          <v-chip-group
            v-if="catalogues.fuentes_emisiones.alcance3.length"
            v-model="scope.alcance3"
            class="mx-3"
            active-class="elegido3 white--text"
          >
            <v-chip
              v-for="(item, idx) in catalogues.fuentes_emisiones.alcance3"
              :ref="'emissionSource-' + item.id"
              :key="idx"
              @click="setEmissionSource(item.id)"
              >{{ item.nombre }}</v-chip
            >
          </v-chip-group>
          <div v-else class="py-3">No se obtuvieron fuentes de emisión</div>
        </v-sheet>
      </v-col>
    </v-row>
    <template v-if="scope.fuente_emision">
      <v-row>
        <v-col cols="12" md="6">
          <v-card class="elevation-5 mt-5 mx-5">
            <v-sheet class="text-center tooltipFour" style="position: relative">
              <v-btn icon style="position: absolute; top: 0; right: 0" small class="tooltipFour" @click="$refs.tooltipGuide.start(3)">
                <v-icon small> mdi-information-outline </v-icon>
              </v-btn>
            </v-sheet>
            <v-card-title dense class="mx-3 pb-1" elevation="0">
              <span class="mt-3"> Huella de carbono </span>
              <v-spacer></v-spacer>
              <!--              <v-select v-model="chartHDC.tipoPeriodo" label="Tipo de periodo" :items="catalogues._tipos_periodos" item-text="text" item-value="value"-->
              <!--              prepend-icon="mdi-calendar-check" dense class="mt-11 ma-2" style="max-width: 190px;" :disabled="chartHDC.loading" @change="setDateChart()"></v-select>-->
              <template v-if="chartHDC.tipoPeriodo === 'mensual'">
                <v-menu
                  ref="menuChart"
                  v-model="chartHDC.menu"
                  :close-on-content-click="false"
                  :return-value.sync="chartHDC.periodo"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="chartHDC.periodo"
                      label="Periodo"
                      v-bind="attrs"
                      dense
                      v-on="on"
                      prepend-icon="mdi-calendar"
                      class="mt-11 ma-2"
                      style="max-width: 190px"
                      :disabled="chartHDC.loading"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="chartHDC.periodo" type="month" locale="es">
                    <v-spacer></v-spacer>
                    <v-btn text color="blue-grey" @click="chartHDC.menu = false"> Cancelar </v-btn>
                    <v-btn
                      text
                      color="success"
                      @click="
                        $refs.menuChart.save(chartHDC.periodo);
                        getChart();
                      "
                    >
                      Aceptar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </template>
              <template v-else-if="chartHDC.tipoPeriodo === 'anual'">
                <v-select
                  v-model="chartHDC.periodo"
                  label="Periodo anual"
                  :items="catalogues.years"
                  item-text="text"
                  item-value="value"
                  prepend-icon="mdi-calendar"
                  dense
                  class="mt-8 ma-2"
                  style="max-width: 190px"
                  light
                  hide-details
                  :disabled="chartHDC.loading"
                  @change="getChart()"
                ></v-select>
              </template>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    :disabled="loading"
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    color="primary"
                    outlined
                    class="mt-5 ma-2"
                    @click="getChart()"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
                <span>Consultar</span>
              </v-tooltip>
            </v-card-title>

            <v-card-text>
              <Line-chart-hdc :chart-data="chartHDC.data" :options="chartHDC.options"></Line-chart-hdc>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="elevation-5 mt-5 mx-5">
            <v-sheet class="text-center tooltipFive" style="position: relative">
              <v-btn icon style="position: absolute; top: 0; right: 0" small @click="$refs.tooltipGuide.start(4)">
                <v-icon small> mdi-information-outline </v-icon>
              </v-btn>
            </v-sheet>
            <v-card-title dense class="mx-3" elevation="0">
              <span class="mt-3"> Ruta cero emisiones </span>
              <v-spacer></v-spacer>
              <v-select
                v-model="chartHDC.netZero"
                label="Subgrupo"
                :items="catalogues.alcances"
                item-text="text"
                item-value="value"
                dense
                class="mt-7 ma-2"
                style="max-width: 190px"
                light
                hide-details
                :disabled="chartHDC.loading"
                @change="getPathwayNetZero()"
              >
              </v-select>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    :disabled="loading"
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    color="primary"
                    outlined
                    class="mt-6 ma-2"
                    @click="getPathwayNetZero()"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
                <span>Consultar</span>
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <Line-chart-hdc :chart-data="chartHDC.data2" :options="chartHDC.options"></Line-chart-hdc>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="elevation-5 mt-5 mx-5" id="fuentesdeemision">
            <v-sheet class="text-center tooltipSix" style="position: relative">
              <v-btn class="white--text" icon style="position: absolute; top: 0; right: 0" small @click="$refs.tooltipGuide.start(5)">
                <v-icon small> mdi-information-outline </v-icon>
              </v-btn>
            </v-sheet>
            <v-card-title dense dark style="background: #5271ff" class="white--text" elevation="2">
              <span> Registros de fuente de emisión </span>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    class="white--text downloadtemplate"
                    small
                    :disabled="record.loading"
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadTemplate()"
                  >
                    Plantilla
                    <v-icon right>mdi-file-excel</v-icon>
                  </v-btn>
                </template>
                <span>Descargar plantilla de ejemplo</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text small :disabled="record.loading" v-bind="attrs" v-on="on" @click="downloadRecords()" class="white--text">
                    Descargar
                    <v-icon right>mdi-arrow-down</v-icon>
                  </v-btn>
                </template>
                <span>Descargar registros consultados</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="white--text importtemplate"
                    text
                    small
                    :disabled="isAuditor() || record.loading"
                    v-bind="attrs"
                    v-on="on"
                    @click="record.dialogs.import = true"
                  >
                    Importar
                    <v-icon right>mdi-arrow-up</v-icon>
                  </v-btn>
                </template>
                <span>Importar archivo con múltiples registros</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="white--text"
                    text
                    small
                    :disabled="isAuditor() || record.loading"
                    v-bind="attrs"
                    v-on="on"
                    @click="record.dialogs.new = true"
                  >
                    Agregar
                    <v-icon right>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Agregar registro</span>
              </v-tooltip>
            </v-card-title>
            <v-card-title>
              <v-row class="mt-2">
                <v-select
                  v-model="record.tipoPeriodo"
                  label="Tipo de periodo"
                  :items="catalogues.tipos_periodos"
                  item-text="text"
                  item-value="value"
                  prepend-icon="mdi-calendar-check"
                  dense
                  class="ml-5 mr-2"
                  style="max-width: 190px"
                  :disabled="record.loading"
                  @change="setDateRecord()"
                ></v-select>
                <template v-if="record.tipoPeriodo === 'mensual'">
                  <v-menu
                    ref="menuRecord"
                    v-model="record.menu"
                    :close-on-content-click="false"
                    :return-value.sync="record.periodo"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="record.periodo"
                        label="Periodo"
                        v-bind="attrs"
                        dense
                        v-on="on"
                        prepend-icon="mdi-calendar"
                        class="mx-2 scrolloneclass"
                        style="max-width: 190px"
                        :disabled="record.loading"
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="record.periodo" type="month" locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="blue-grey" @click="record.menu = false"> Cancelar </v-btn>
                      <v-btn
                        text
                        color="success"
                        @click="
                          $refs.menuRecord.save(record.periodo);
                          getRecord();
                        "
                      >
                        Aceptar
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </template>
                <template v-else-if="record.tipoPeriodo === 'anual'">
                  <v-select
                    v-model="record.periodo"
                    label="Periodo"
                    :items="catalogues.years"
                    item-text="text"
                    item-value="value"
                    prepend-icon="mdi-calendar"
                    dense
                    class="mx-2"
                    style="max-width: 190px"
                    light
                    hide-details
                    :disabled="record.loading"
                    @change="getRecord()"
                  ></v-select>
                </template>
                <v-btn small :disabled="record.loading" elevation="0" color="primary" outlined class="text-normal mx-2" @click="getRecord()">
                  Consultar
                  <v-icon right>mdi-magnify</v-icon>
                </v-btn>
                <v-btn
                  v-if="record.selected.length"
                  small
                  :disabled="record.loading"
                  elevation="0"
                  color="error"
                  outlined
                  class="text-normal mx-2"
                  @click="removeSelectedRecord()"
                >
                  Eliminar ({{ record.selected.length }})
                  <v-icon right>mdi-delete</v-icon>
                </v-btn>
              </v-row>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="record.search"
                append-icon="mdi-magnify"
                label="Buscar"
                class="mx-2 addtemplate"
                single-line
                hide-details
                maxlength="150"
              ></v-text-field>
            </v-card-title>
            <v-data-table
              v-model="record.selected"
              :headers="record.headers"
              :items="record.items"
              :loading="record.loading"
              :search="record.search"
              show-select
              :single-select="false"
              :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
              :items-per-page="10"
              item-key="id"
              class="elevation-1"
              dense
            >
              <v-progress-linear slot="progress" color="primary" indeterminate></v-progress-linear>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left
                  ><template v-slot:activator="{ on, attrs }"
                    ><v-icon class="mr-2" size="22" color="blue-grey" dark v-bind="attrs" v-on="on" :disabled="loading" @click="viewRecord(item)"
                      >mdi-eye</v-icon
                    ></template
                  ><span>Mostrar</span></v-tooltip
                >
                <v-menu top offset-y close-on-click>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-2" size="22" color="blue-grey" dark v-bind="attrs" v-on="on" :disabled="isAuditor() || loading"
                      >mdi-dots-vertical</v-icon
                    >
                  </template>
                  <v-list>
                    <v-list-item
                      @click="
                        record.isUpdate = true;
                        viewRecord(item);
                      "
                    >
                      <v-list-item-title>Editar</v-list-item-title>
                      <v-icon class="ml-2" size="22" color="blue-grey">mdi-pencil</v-icon>
                    </v-list-item>
                    <v-list-item @click="removeRecord(item.id)">
                      <v-list-item-title>Eliminar</v-list-item-title>
                      <v-icon class="ml-2" size="22" color="error">mdi-delete</v-icon>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
              </template>
              <template v-slot:no-data> No hay información disponible </template>
              <template v-slot:no-results> No se obtuvieron resultados </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-dialog v-model="record.dialogs.new" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Nuevo registro</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="record.loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="record.loading" @click="saveRecord()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row class="mt-2">
              <v-col cols="6">
                <v-select
                  v-model="record.tipoPeriodo"
                  label="Tipo de periodo"
                  :items="catalogues.tipos_periodos"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  readonly
                  required
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="record.periodo" label="Periodo" dense outlined readonly required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="record.bloqueo"
                  label="No hay información disponible"
                  :disabled="record.loading"
                  @change="updateBloqued()"
                ></v-switch>
              </v-col>
            </v-row>
            <v-form ref="formNew" lazy-validation>
              <template v-for="(item, idx) in record.columns">
                <v-row :key="idx">
                  <v-col cols="12">
                    <v-text-field
                      v-if="item.tipo !== 'catalogo'"
                      v-model="record.forms.new[item.id]"
                      :label="item.nombre"
                      :rules="record.rules[item.id]"
                      :disabled="record.loading || record.bloqueo"
                      required
                    ></v-text-field>
                    <v-autocomplete
                      v-else
                      v-model="record.forms.new[item.id]"
                      :items="record.catalogues[item.id + '_' + record.periodo.split('-')[0]]"
                      :label="item.nombre"
                      :rules="record.rules[item.id]"
                      :disabled="record.loading || record.bloqueo"
                      required
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </template>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="record.dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Detalle de registro</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="record.loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn v-if="record.isUpdate" text small :loading="record.loading" @click="updateRecord()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row class="mt-2">
              <v-col cols="6">
                <v-select
                  v-model="record.forms.update.tipo_periodo"
                  label="Tipo de periodo"
                  :items="catalogues.tipos_periodos"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  readonly
                  required
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="record.forms.update.periodo" label="Periodo" dense outlined readonly required></v-text-field>
              </v-col>
            </v-row>
            <v-form ref="formUpdate" lazy-validation>
              <template v-for="(item, idx) in record.columns">
                <v-row :key="idx">
                  <v-col cols="12">
                    <template v-if="item.id !== 'id' && item.id !== 'tipo_periodo' && item.id !== 'periodo'">
                      <v-text-field
                        v-if="item.tipo !== 'catalogo'"
                        v-model="record.forms.update[item.id]"
                        :label="item.nombre"
                        :rules="record.rules[item.id]"
                        :readonly="!record.isUpdate"
                        required
                      ></v-text-field>
                      <v-autocomplete
                        v-else
                        v-model="record.forms.update[item.id]"
                        :items="record.catalogues[item.id + '_' + record.periodo.split('-')[0]]"
                        :label="item.nombre"
                        :rules="record.rules[item.id]"
                        :readonly="!record.isUpdate"
                        required
                      ></v-autocomplete>
                    </template>
                  </v-col>
                </v-row>
              </template>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="record.dialogs.import" persistent max-width="800px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Importar registros</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="record.loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn v-if="!record.forms.import.data.items.length" text small :loading="record.loading" @click="accept()">
            Cargar
            <v-icon right>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn v-else-if="record.forms.import.data.items.length" text small :loading="record.loading" @click="saveImportRecord()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row class="mt-2">
              <v-col cols="6">
                <v-select
                  v-model="record.tipoPeriodo"
                  label="Tipo de periodo"
                  :items="catalogues.tipos_periodos"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  readonly
                  required
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="record.periodo" label="Periodo" dense outlined readonly required></v-text-field>
              </v-col>
            </v-row>
            <v-form v-show="!record.forms.import.data.items.length" ref="formImport" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="record.forms.import.id"
                    chips
                    show-size
                    label="Seleccionar archivo..."
                    :accept="catalogues.mime_types"
                    :disabled="record.loading || record.bloqueo"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    v-model="record.forms.import.replace"
                    label="Reemplazar registros anteriores"
                    :disabled="record.loading || record.bloqueo"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
            <v-data-table
              v-show="record.forms.import.data.items.length"
              :headers="record.forms.import.data.headers"
              :items="record.forms.import.data.items"
              :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [10, 20, -1] }"
              :items-per-page="10"
            >
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
              </template>
              <template v-slot:no-data> No hay información disponible </template>
              <template v-slot:no-results> No se obtuvieron resultados </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="record.dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar el registro?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="record.loading" color="blue-grey" @click="cancel()"> Cancelar </v-btn>
          <v-btn text small :loading="record.loading" color="error" @click="removeRecord()"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="record.dialogs.removeSelected" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar los registros?</v-card-text>
        <v-card-text
          >Elementos seleccionados: <span class="font-weight-bold">{{ record.selected.length }}</span></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="record.loading" color="blue-grey" @click="cancel()"> Cancelar </v-btn>
          <v-btn text small :loading="record.loading" color="error" @click="removeSelectedRecord()"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay
      absolute
      :value="
        loading ||
        !company ||
        (!catalogues.fuentes_emisiones.alcance1.length &&
          !catalogues.fuentes_emisiones.alcance2.length &&
          !catalogues.fuentes_emisiones.alcance3.length)
      "
      opacity="0.8"
    >
      <div v-if="loading" class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Cargando...</p>
      </div>
      <div v-else-if="!loading && !company" class="text-center">Seleccionar una empresa...</div>
      <div
        v-else-if="
          !loading &&
          !catalogues.fuentes_emisiones.alcance1.length &&
          !catalogues.fuentes_emisiones.alcance2.length &&
          !catalogues.fuentes_emisiones.alcance3.length
        "
        class="text-center"
      >
        No se obtuvieron fuentes de emisión para la empresa actual...
      </div>
    </v-overlay>
    <v-tour name="huellaDeCarbono" :steps="steps" :options="{ highlight: true }">
      <template slot-scope="tour">
        <transition name="">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :highlight="tour.highlight"
          >
          </v-step>
        </transition>
      </template>
    </v-tour>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
    <TourGuideHuella
      ref="homeGuideGuia"
      v-on:scrollone="scrollone"
      v-on:goTop="goTop"
      :steps="tourSteps"
      v-on:abrirdrawer="abrirDrawer"
      v-on:goHome="goHome"
    />
    <CustomCardTooltip ref="tooltipGuide" :tooltips="tooltips" />
    <v-btn id="medidasdereduccionButton" @click="scrollToMedidas" x-small color="white" class="elevation-0 white--text">Hola</v-btn>
    <div class="lafuente"></div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import services from '@/utils/services';
import LineChartHdc from '../components/HDC/Chart.js';
import moment from 'moment-timezone';
import downloadjs from 'downloadjs';
// import * as XLSX from 'xlsx';
import * as Papa from 'papaparse';
import _ from 'lodash';
import { EventBus } from '@/EventBus';
import CustomCardTooltip from '@/components/CustomCardTooltip.vue';
import { TooltipsTwo } from '@/utils/CatalogTooltips';
import TourGuideHuella from '@/components/TourGuideHuella.vue';
import * as XLSX from 'xlsx-js-style';
import writeXlsxFile from 'write-excel-file';

export default {
  name: 'huellaDeCarbono',
  components: {
    TourGuideHuella,
    CustomCardTooltip,
    LineChartHdc
  },
  data: () => ({
    hdc: [],
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    rol: [],
    excelData: [],
    catalogues: {
      fuentes_emisiones: {
        alcance1: [],
        alcance2: [],
        alcance3: [],
        completado1: 0,
        completado2: 0,
        completado3: 0
      },
      mime_types: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
      tipos_periodos: [
        { text: 'Mensual', value: 'mensual' },
        { text: 'Anual', value: 'anual' }
      ],
      _tipos_periodos: [{ text: 'Anual', value: 'anual' }],
      years: [],
      alcances: [
        { text: 'Alcance 1', value: '1' },
        { text: 'Alcance 2', value: '2' },
        { text: 'Alcance 3', value: '3' },
        { text: 'Total', value: 'total' }
      ]
    },
    scope: {
      alcance1: null,
      alcance2: null,
      alcance3: null,
      fuente_emision: null
    },
    chartHDC: {
      tipoPeriodo: 'anual',
      periodo: null,
      netZero: 'total',
      menu: false,
      loading: false,
      data: null,
      data2: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Toneladas de CO2e',
                fontFamily: 'Quicksand',
                fontSize: 13,
                fontStyle: 'bold'
              }
            }
          ]
        }
      }
    },
    record: {
      plantilla: '',
      tipoPeriodo: 'mensual',
      periodo: '',
      bloqueo: false,
      menu: false,
      loading: false,
      search: '',
      headers: [],
      items: [],
      selected: [],
      deleted: null,
      isUpdate: false,
      catalogues: [],
      columns: [],
      forms: {
        new: {},
        update: {},
        import: {
          id: null,
          replace: false,
          data: {
            headers: [],
            items: []
          }
        }
      },
      rules: {},
      dialogs: {
        new: false,
        update: false,
        import: false,
        remove: false,
        removeSelected: false
      }
    },
    steps: [
      {
        target: '.v-step-0',
        content: 'Selecciona una empresa para visualizar información',
        params: {
          placement: 'top'
        }
      },
      {
        target: '.v-step-1',
        content: 'Selecciona una fuente de emisión disponible',
        params: {
          placement: 'top'
        }
      }
    ],
    tourSteps: [
      {
        selector: '.alcanceuno',
        title: 'Huella de carbono',
        description: 'Encuentra tu alcance y la categoria que deseas llenar.',
        position: 'bottom',
        action: 'scrollone'
      },
      {
        selector: '.scrolloneclass',
        title: '',
        description: 'Elige el periodo indicado. Recuerda seleccionar <strong>Aceptar</strong>.',
        position: 'right'
      },
      {
        selector: '.downloadtemplate',
        title: 'Descargar',
        description: 'Descarga la plantilla.',
        position: 'bottom'
      },
      {
        selector: '.importtemplate',
        title: 'Importar',
        description: 'Después de llenar la plantilla, súbela a la plataforma en <strong>Importar</strong>',
        position: 'bottom'
      },
      {
        selector: '.addtemplate',
        title: 'Editar',
        description:
          'Para hacer un cambio, hay dos opciones:\n' +
          '<ol style="text-align: left; padding-left: 20px;">\n' +
          '  <li>\n' +
          '    Importar otra plantilla (solo asegúrate de hacer clic en el botón de "Reemplazar registros anteriores")\n' +
          '  </li>\n' +
          '  <li>\n' +
          '    Editar\n' +
          '  </li>\n' +
          '</ol>',
        position: 'left',
        action: 'goTop'
      },
      {
        selector: '.downloadreport',
        title: 'Reporte',
        description: 'Descarga tu reporte con toda la información.',
        position: 'bottom',
        action: 'drawer'
      }
    ],
    val1: false,
    val2: false,
    val3: false,
    val4: false,
    val5: false,
    val6: false,
    tooltips: TooltipsTwo
  }),
  mounted() {
    if (this.$route.query.start) {
      this.checkLocal(); // Método que inicias tu tour o el clic simulado
    }
    this.checkLocal();
    this.check();
    this.setCatYears();
    this.setDateChart();
    this.setDateRecord();
    this.refresh(this.$store.state.general.company);
    // this.tourGuideGuia()
  },
  computed: {
    company() {
      return this.$store.state.general.company;
    },
    report() {
      return this.$store.state.general.report;
    },
    excelReport() {
      return this.$store.state.general.excelReport;
    }
  },
  watch: {
    '$route.query.start'(newValue) {
      if (newValue) {
        this.checkLocal(); // Ejecutar tu función cuando cambie el parámetro 'start'
      }
    },
    company: function (val) {
      this.loading = true;
      this.clear();
      setTimeout(() => {
        this.refresh(val);
      }, 800);
    },
    report: function (val) {
      if (val) {
        this.getReportGeneral();
      }
    },
    excelReport: function (val) {
      if (val) {
        this.exportToExcel();
        this.$store.dispatch('general/setExcelReport', { val: false });
      }
    }
  },
  methods: {
    ...mapActions('general', ['setReport', 'setExcelReport']),
    goTop() {
      return new Promise((resolve) => {
        this.$vuetify.goTo(0, {
          duration: 300,
          offset: 0,
          easing: 'easeInOutCubic',
          callback: () => resolve()
        });
      });
    },
    checkLocal() {
      let tour = JSON.parse(localStorage.getItem('tour'));
      if (tour.type == 1) {
        this.tourSteps.push({
          selector: '.card',
          title: '',
          description: 'Selecciona tu objetivo.',
          position: 'rigth',
          rigth: '50px',
          action: 'goHome'
        });
      }
      this.tourGuideGuia(tour);
    },
    abrirDrawer() {
      //this.$emit('abrirdrawer');
      let tour = JSON.parse(localStorage.getItem('tour'));
      if (tour.type == 1) {
        EventBus.$emit('open-drawer');
      }
    },
    goHome() {
      let tour = JSON.parse(localStorage.getItem('tour'));

      if (tour) {
        tour.one = true;
        tour.two = true;
        tour.three = false;
      } else {
        tour = { one: true, two: true, three: false };
      }

      localStorage.setItem('tour', JSON.stringify(tour));
      this.$router.push({ path: 'home' });
    },
    tourGuideGuia(tour) {
      if (!tour.two) {
        this.$refs.homeGuideGuia.start();
      }
    },
    scrollToMedidas() {
      const element = document.getElementById('lafuente');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    scrollone() {
      const element = document.querySelector('.lafuente'); // O el ID o clase del elemento donde quieres desplazarte
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    check() {
      this.rol = this.$session.get('rol');
    },
    refresh(id) {
      if (id) {
        this.loading = true;
        this.catalogues.fuentes_emisiones = {
          alcance1: [],
          alcance2: [],
          alcance3: [],
          completado1: 0,
          completado2: 0,
          completado3: 0
        };
        this.axios
          .get(services.routes.emissionSource + '?id=' + id)
          .then((response) => {
            const source = response.data.data;
            if (source) {
              for (let i = 0; i < source.length; i++) {
                if (source[i].alcance == 1) {
                  this.catalogues.fuentes_emisiones.alcance1.push(source[i]);
                  if (source[i].completado) {
                    this.catalogues.fuentes_emisiones.completado1 += 1;
                  }
                } else if (source[i].alcance == 2) {
                  this.catalogues.fuentes_emisiones.alcance2.push(source[i]);
                  if (source[i].completado) {
                    this.catalogues.fuentes_emisiones.completado2 += 1;
                  }
                } else if (source[i].alcance == 3) {
                  this.catalogues.fuentes_emisiones.alcance3.push(source[i]);
                  if (source[i].completado) {
                    this.catalogues.fuentes_emisiones.completado3 += 1;
                  }
                }
              }
              setTimeout(() => {
                for (let i = 0; i < this.catalogues.fuentes_emisiones.alcance2.length; i++) {
                  if (this.catalogues.fuentes_emisiones.alcance2[i].nombre === 'Electricidad') {
                    const id = this.catalogues.fuentes_emisiones.alcance2[i].id;
                    this.$refs['emissionSource-' + id][0].click();
                    break;
                  }
                }
              }, 300);
            }
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      }
    },
    clear() {
      (this.catalogues.fuentes_emisiones = {
        alcance1: [],
        alcance2: [],
        alcance3: [],
        completado1: 0,
        completado2: 0,
        completado3: 0
      }),
        (this.scope.alcance1 = null);
      this.scope.alcance2 = null;
      this.scope.alcance3 = null;
      this.scope.fuente_emision = null;
      this.chartHDC.data = null;
      this.chartHDC.data2 = null;
      this.record.plantilla = '';
      this.record.headers = [];
      this.record.items = [];
      this.record.catalogues = [];
      this.record.columns = [];
      this.record.forms.new = {};
      this.record.forms.update = {};
      this.record.forms.import = {
        id: null,
        replace: false,
        data: {
          headers: [],
          items: []
        }
      };
      this.record.rules = {};
    },
    cancel() {
      if (this.record.dialogs.new) {
        this.$refs.formNew.reset();
        for (let key in this.record.forms.new) {
          this.record.forms.new[key] = '';
        }
        this.record.dialogs.new = false;
      } else if (this.record.dialogs.update) {
        this.$refs.formUpdate.reset();
        for (let key in this.record.forms.update) {
          this.record.forms.update[key] = '';
        }
        this.record.isUpdate = false;
        this.record.dialogs.update = false;
      } else if (this.record.dialogs.import) {
        this.record.forms.import = {
          id: null,
          replace: false,
          data: {
            headers: [],
            items: []
          }
        };
        this.record.dialogs.import = false;
      } else if (this.record.dialogs.remove) {
        this.record.deleted = null;
        this.record.dialogs.remove = false;
      } else if (this.record.dialogs.removeSelected) {
        this.record.selected = [];
        this.record.dialogs.removeSelected = false;
      }
    },
    setEmissionSource(id) {
      this.scope.alcance1 = null;
      this.scope.alcance2 = null;
      this.scope.alcance3 = null;
      if (this.scope.fuente_emision == id) {
        this.scope.fuente_emision = null;
      } else {
        this.scope.fuente_emision = id;
        this.getDetailSource();
      }
    },
    setCatYears() {
      let year = parseInt(moment().format('YYYY'));
      for (let i = 0; i < 5; i++) {
        this.catalogues.years.unshift((year - i).toString());
      }
    },
    setDateChart() {
      if (this.chartHDC.tipoPeriodo === 'mensual') {
        this.chartHDC.periodo = moment().format('YYYY-MM');
      } else if (this.chartHDC.tipoPeriodo === 'anual') {
        this.chartHDC.periodo = moment().format('YYYY');
      }
      if (this.scope.fuente_emision) {
        this.getChart();
      }
    },
    setDateRecord() {
      if (this.record.tipoPeriodo === 'mensual') {
        this.record.periodo = moment().format('YYYY-MM');
      } else if (this.record.tipoPeriodo === 'anual') {
        this.record.periodo = moment().format('YYYY');
      }
      if (this.scope.fuente_emision) {
        this.getRecord();
      }
    },
    getDetailSource() {
      this.loading = true;
      this.clearTemplate();
      this.axios
        .get(
          services.routes.emissionSource +
            '/detail' +
            '?empresa=' +
            this.$store.state.general.company +
            '&fuente_emision=' +
            this.scope.fuente_emision
        )
        .then((response) => {
          let data = response.data.data;
          this.makeCatTemplate(data);
          this.makeHeadersTemplate(data);
          this.makeRulesForm(data);
          this.makeFormTemplate(data);
          this.getRecord();
          this.getChart();
          // this.getPathwayNetZero()
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || 'Servicio no disponible';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getChart() {
      this.chartHDC.loading = true;
      this.axios
        .get(
          services.routes.chart +
            '?empresa=' +
            this.$store.state.general.company +
            '&fuente_emision=' +
            this.scope.fuente_emision +
            '&tipo_periodo=' +
            this.chartHDC.tipoPeriodo +
            '&periodo=' +
            this.chartHDC.periodo
          // services.routes.emissionByCategory +
          //   '?empresa=' +
          //   this.$store.state.general.company +
          //   '&tipo_periodo=' +
          //   this.chartHDC.tipoPeriodo +
          //   '&periodo=' +
          //   this.chartHDC.periodo
        )
        .then((response) => {
          let periodos = response.data.data;
          let labels = [];
          let data = [];
          for (let key in periodos) {
            labels.push(key);
            data.push(periodos[key]);
          }
          this.chartHDC.data = {
            labels: labels,
            datasets: [
              {
                label: 'Toneladas de CO2e',
                borderColor: 'F89679',
                backgroundColor: 'rgba(248, 150, 121, 0.8)',
                data: data,
                borderWidth: 1,
                lineTension: 0
              }
            ]
          };

          setTimeout(() => {
            this.getPathwayNetZero();
          }, 300);
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || 'Servicio no disponible';
        })
        .finally(() => {
          this.chartHDC.loading = false;
        });
    },
    getPathwayNetZero() {
      this.chartHDC.loading = true;
      this.axios
        .get(
          services.routes.pathwayNetZero +
            '?empresa=' +
            this.$store.state.general.company +
            '&fuente_emision=' +
            this.scope.fuente_emision +
            '&tipo_periodo=' +
            this.chartHDC.tipoPeriodo +
            '&periodo=' +
            this.chartHDC.periodo +
            '&alcance=' +
            this.chartHDC.netZero
        )
        .then((response) => {
          this.chartHDC.data2 = response.data.data;
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || 'Servicio no disponible';
        })
        .finally(() => {
          this.chartHDC.loading = false;
        });
    },
    async getRecord() {
      this.record.selected = [];
      this.record.loading = true;
      const bloqued = await this.axios.get(
        services.routes.record +
          '/bloqued' +
          '?empresa=' +
          this.$store.state.general.company +
          '&fuente_emision=' +
          this.scope.fuente_emision +
          '&tipo_periodo=' +
          this.record.tipoPeriodo +
          '&periodo=' +
          this.record.periodo
      );
      if (_.has(bloqued.data, 'data')) {
        this.record.bloqueo = bloqued.data.data;
      }
      this.axios
        .get(
          services.routes.record +
            '?empresa=' +
            this.$store.state.general.company +
            '&fuente_emision=' +
            this.scope.fuente_emision +
            '&tipo_periodo=' +
            this.record.tipoPeriodo +
            '&periodo=' +
            this.record.periodo
        )
        .then((response) => {
          this.record.items = response.data.data;
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || 'Servicio no disponible';
        })
        .finally(() => {
          this.record.loading = false;
        });
    },
    updateBloqued() {
      this.record.loading = true;
      let control = {
        empresa: this.$store.state.general.company,
        fuente_emision: this.scope.fuente_emision,
        tipo_periodo: this.record.tipoPeriodo,
        periodo: this.record.periodo,
        reemplazar: false
      };
      if (this.record.bloqueo) {
        this.axios
          .post(services.routes.record + '/bloqued', { control })
          .then((response) => {})
          .catch((error) => {
            this.record.bloqueo = !this.record.bloqueo;
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.record.loading = false;
          });
      } else {
        this.axios
          .delete(services.routes.record + '/bloqued', { data: { control } })
          .then((response) => {})
          .catch((error) => {
            this.record.bloqueo = !this.record.bloqueo;
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.record.loading = false;
          });
      }
    },
    clearTemplate() {
      this.chartHDC.data = null;
      this.chartHDC.data2 = null;
      this.record.plantilla = '';
      this.record.loading = false;
      this.record.headers = [];
      this.record.items = [];
      this.record.selected = [];
      this.record.deleted = null;
      this.record.isUpdate = false;
      this.record.catalogues = [];
      this.record.columns = [];
      this.record.forms.new = {};
      this.record.forms.update = {};
      this.record.forms.import = {
        id: null,
        replace: false,
        data: {
          headers: [],
          items: []
        }
      };
      this.record.rules = {};
    },
    makeCatTemplate(data) {
      for (let key in data.catalogos) {
        this.record.catalogues[key] = [];
        for (let i = 0; i < data.catalogos[key].valores.length; i++) {
          this.record.catalogues[key].push(data.catalogos[key].valores[i][0]);
        }
      }
    },
    makeHeadersTemplate(data) {
      for (let i = 0; i < data.plantilla.columnas.length; i++) {
        if (data.plantilla.columnas[i].id !== 'COMENTARIOS') {
          this.record.headers.push({
            text: data.plantilla.columnas[i].nombre,
            align: 'left',
            sortable: false,
            value: data.plantilla.columnas[i].id
          });
        }
      }
      this.record.headers.push({
        text: 'Periodo',
        align: 'left',
        sortable: false,
        value: 'periodo'
      });
      this.record.headers.push({
        text: '',
        align: 'left',
        sortable: false,
        value: 'actions'
      });
    },
    makeRulesForm(data) {
      for (let i = 0; i < data.plantilla.columnas.length; i++) {
        let rules = [];
        if (data.plantilla.columnas[i].tipo === 'texto') {
          rules = [(v) => !!v || 'El campo es requerido', (v) => (v && v.length <= 150) || 'El campo excede la longitud máxima'];
        } else if (data.plantilla.columnas[i].tipo === 'entero') {
          rules = [
            (v) => !!v || 'El campo es requerido',
            (v) => (/^\d{1,10}$/.test(v) && parseInt(v) >= 0 && parseInt(v) <= 9999999999) || 'El campo es inválido (número entero)'
          ];
        } else if (data.plantilla.columnas[i].tipo === 'decimal') {
          rules = [
            (v) => !!v || 'El campo es requerido',
            (v) =>
              (/^\d{1,10}(\.\d{1,5})?$/.test(v) && parseFloat(v) >= 0 && parseFloat(parseFloat(v).toFixed(5)) <= 9999999999.99999) ||
              'El campo es inválido (número decimal)'
          ];
        } else if (data.plantilla.columnas[i].tipo === 'catalogo') {
          rules = [(v) => !!v || 'El campo es requerido'];
        }
        this.record.rules[data.plantilla.columnas[i].id] = rules;
      }
    },
    makeFormTemplate(data) {
      this.record.columns = data.plantilla.columnas;
      this.record.plantilla = data.plantilla.id;
      for (let i = 0; i < this.record.columns.length; i++) {
        this.record.forms.new[this.record.columns[i].id] = '';
      }
    },
    downloadTemplate() {
      this.record.loading = true;
      this.axios
        .get(
          services.routes.emissionSource +
            '/template' +
            '?empresa=' +
            this.$store.state.general.company +
            '&fuente_emision=' +
            this.scope.fuente_emision
        )
        .then((response) => {
          let data = response.data.data;
          downloadjs(data.archivo, data.nombre, data.archivo.split(';')[0].split(':')[1]);
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || 'Servicio no disponible';
        })
        .finally(() => {
          this.record.loading = false;
        });
    },
    // downloadRecords() {
    //   let data = [];
    //   for (let i = 0; i < this.record.items.length; i++) {
    //     let row = {};
    //     for (let j = 0; j < this.record.columns.length; j++) {
    //       row[this.record.columns[j].nombre] = this.record.items[i][this.record.columns[j].id];
    //     }
    //     row['Periodo'] = this.record.items[i].periodo;
    //     data.push(row);
    //   }
    //   if (data.length) {
    //     const csv = Papa.unparse(data);
    //     downloadjs(csv, 'Registros_' + this.record.tipoPeriodo + '_' + this.record.periodo + '.csv', 'text/csv');
    //   } else {
    //     this.error = true;
    //     this.msgError = 'No hay información disponible';
    //   }
    // },

    downloadRecords() {
      let data = [];
      for (let i = 0; i < this.record.items.length; i++) {
        let row = {};
        for (let j = 0; j < this.record.columns.length; j++) {
          row[this.record.columns[j].nombre] = this.record.items[i][this.record.columns[j].id];
        }
        row['Periodo'] = this.record.items[i].periodo;
        data.push(row);
      }

      if (data.length) {
        // Crea una hoja de trabajo (worksheet) a partir de los datos
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Crea un libro de trabajo (workbook) y agrega la hoja
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Registros');

        // Genera el archivo XLSX como un Blob
        const xlsxData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([xlsxData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Usa downloadjs para descargar el archivo
        downloadjs(
          blob,
          `Registros_${this.record.tipoPeriodo}_${this.record.periodo}.xlsx`,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
      } else {
        this.error = true;
        this.msgError = 'No hay información disponible';
      }
    },
    accept() {
      if (this.record.forms.import.id) {
        let fr = new FileReader();
        let file = this.record.forms.import.id;
        let size = this.record.forms.import.id.size;
        if (size <= 5000000) {
          this.record.loading = true;
          setTimeout(() => {
            fr.onload = () => {
              let data = XLSX.read(fr.result, { type: 'array' });
              let sheetNames = data.SheetNames;
              let worksheet = data.Sheets[sheetNames[0]];
              let csv = XLSX.utils.sheet_to_csv(worksheet).trim();
              let values = Papa.parse(csv);
              if (values.data && values.data.length) {
                values = values.data;
                this.loadRecord(values);
              } else {
                this.error = true;
                this.msgError = 'El archivo no fue leído correctamente';
              }
              this.record.loading = false;
            };
            fr.onerror = () => {
              this.record.loading = false;
              this.record.forms.import.id = null;
              this.error = true;
              this.msgError = 'El archivo no fue leído correctamente';
            };
            fr.readAsArrayBuffer(file);
          }, 500);
        } else {
          this.error = true;
          this.msgError = 'El archivo ha excedido el límite permitido (5 MB)';
        }
      } else {
        this.error = true;
        this.msgError = 'Seleccionar archivo';
      }
    },
    loadRecord(data) {
      for (let i = 0; i < this.record.columns.length; i++) {
        this.record.forms.import.data.headers.push({
          text: this.record.columns[i].nombre,
          align: 'left',
          sortable: false,
          value: this.record.columns[i].id
        });
      }
      if (data.length > 1 && data[1].length == this.record.columns.length) {
        for (let i = 1; i < data.length; i++) {
          let check = false;
          let row = {};
          for (let j = 0; j < this.record.columns.length; j++) {
            if (data[i][j] !== '') {
              check = true;
            }
            row[this.record.columns[j].id] = data[i][j];
          }
          if (check) {
            this.record.forms.import.data.items.push(row);
          }
        }
      } else {
        this.error = true;
        this.msgError = 'El formato del archivo es inválido';
      }
    },
    checkImportRecords() {
      const columns = this.record.columns;
      const catalogues = this.record.catalogues;
      const values = this.record.forms.import.data.items;
      const regex = {
        texto: /^.{1,150}$/,
        entero: /^\d{1,10}$/,
        decimal: /^\d{1,10}(\.\d{1,5})?$/
      };
      for (let i = 0; i < values.length; i++) {
        for (let j = 0; j < columns.length; j++) {
          let id = columns[j].id;
          let name = columns[j].nombre;
          let value = values[i][id];
          if (!_.has(values[i], id)) {
            this.error = true;
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`;
            return false;
          } else if (columns[j].tipo === 'texto' && !regex.texto.test(value)) {
            this.error = true;
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`;
            return false;
          } else if (columns[j].tipo === 'entero' && !(regex.entero.test(value) && parseInt(value) >= 0 && parseInt(value) <= 9999999999)) {
            this.error = true;
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`;
            return false;
          } else if (
            columns[j].tipo === 'decimal' &&
            !(regex.decimal.test(value) && parseFloat(value) >= 0 && parseFloat(parseFloat(value).toFixed(5)) <= 9999999999.99999)
          ) {
            this.error = true;
            this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`;
            return false;
          } else if (columns[j].tipo === 'catalogo') {
            id = id + '_' + this.record.periodo.split('-')[0];
            if (!_.has(catalogues, id)) {
              this.error = true;
              this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`;
              return false;
            }
            let check = false;
            for (let k = 0; k < catalogues[id].length; k++) {
              if (catalogues[id][k] === value) {
                check = true;
                break;
              }
            }
            if (!check) {
              this.error = true;
              this.msgError = `Error en la fila ${i + 1}, la columna "${name}" es inválida`;
              return false;
            }
          }
        }
      }
      return true;
    },
    saveImportRecord() {
      if (this.checkImportRecords()) {
        this.record.loading = true;
        let control = {
          empresa: this.$store.state.general.company,
          fuente_emision: this.scope.fuente_emision,
          tipo_periodo: this.record.tipoPeriodo,
          periodo: this.record.periodo,
          reemplazar: this.record.forms.import.replace
        };
        let data = JSON.parse(JSON.stringify(this.record.forms.import.data.items));
        this.axios
          .post(services.routes.record, { control, data })
          .then((response) => {
            this.cancel();
            this.getRecord();
            this.success = true;
            this.msgSuccess = 'Registros guardados exitosamente';
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.record.loading = false;
          });
      }
    },
    saveRecord() {
      if (this.$refs.formNew.validate()) {
        this.record.loading = true;
        let control = {
          empresa: this.$store.state.general.company,
          fuente_emision: this.scope.fuente_emision,
          tipo_periodo: this.record.tipoPeriodo,
          periodo: this.record.periodo,
          reemplazar: false
        };
        let data = JSON.parse(JSON.stringify(this.record.forms.new));
        this.axios
          .post(services.routes.record, { control, data })
          .then((response) => {
            this.cancel();
            this.getRecord();
            this.success = true;
            this.msgSuccess = 'Registro guardado exitosamente';
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.record.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = 'La información es incorrecta';
      }
    },
    viewRecord(data) {
      for (let key in data) {
        this.record.forms.update[key] = data[key];
      }
      this.record.dialogs.update = true;
    },
    updateRecord() {
      if (this.$refs.formUpdate.validate()) {
        this.record.loading = true;
        let control = {
          empresa: this.$store.state.general.company,
          fuente_emision: this.scope.fuente_emision,
          tipo_periodo: this.record.forms.update.tipo_periodo,
          periodo: this.record.forms.update.periodo,
          reemplazar: false
        };
        let data = JSON.parse(JSON.stringify(this.record.forms.update));
        delete data.tipo_periodo;
        delete data.periodo;
        this.axios
          .put(services.routes.record, { control, data })
          .then((response) => {
            this.cancel();
            this.getRecord();
            this.success = true;
            this.msgSuccess = 'Registro actualizado exitosamente';
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.record.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = 'La información es incorrecta';
      }
    },
    removeRecord(id) {
      if (!id) {
        this.record.loading = true;
        let id = this.record.deleted;
        this.axios
          .delete(services.routes.record, { data: { data: { id: this.record.deleted } } })
          .then((response) => {
            this.cancel();
            this.getRecord();
            this.success = true;
            this.msgSuccess = 'Registro eliminado exitosamente';
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.record.loading = false;
          });
      } else {
        this.record.deleted = id;
        this.record.dialogs.remove = true;
      }
    },
    isAuditor() {
      if (this.rol.indexOf('auditor') >= 0) {
        return true;
      }
      return false;
    },
    getReportGeneral() {
      const alcance1 = _.get(this.catalogues, 'fuentes_emisiones.alcance1', []);
      const alcance2 = _.get(this.catalogues, 'fuentes_emisiones.alcance2', []);
      const alcance3 = _.get(this.catalogues, 'fuentes_emisiones.alcance3', []);
      if (!alcance1.length && !alcance2.length && alcance3.length == 1 && alcance3[0].nombre === 'Basura generada') {
        this.loading = true;
        let params = {
          empresa: this.$store.state.general.company,
          tipo_periodo: this.record.tipoPeriodo,
          periodo: this.record.periodo,
          empresas: [this.$store.state.general.company],
          tipo_reporte: 'economia-circular'
        };
        this.axios
          .get(services.routes.ec.report, { params })
          .then((response) => {
            let data = response.data.data;
            let year = this.record.tipoPeriodo === 'anual' ? this.record.periodo : this.record.periodo.split('-')[0];
            downloadjs('data:application/pdf;base64,' + data, 'Reporte_' + year + '.pdf', 'application/pdf');
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.setReport({ val: false });
            this.loading = false;
          });
      } else {
        this.loading = true;
        this.axios
          .get(
            services.routes.report +
              '?empresa=' +
              this.$store.state.general.company +
              '&tipo_periodo=' +
              this.record.tipoPeriodo +
              '&periodo=' +
              this.record.periodo +
              '&tipo_reporte=general'
          )
          .then((response) => {
            let data = response.data.data;
            let year = this.record.tipoPeriodo === 'anual' ? this.record.periodo : this.record.periodo.split('-')[0];
            downloadjs('data:application/pdf;base64,' + data, 'Reporte_' + year + '.pdf', 'application/pdf');
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.setReport({ val: false });
            this.loading = false;
          });
      }
    },
    removeSelectedRecord() {
      if (this.record.dialogs.removeSelected) {
        this.record.loading = true;
        let ids = [];
        for (let rec of this.record.selected) {
          ids.push(rec.id);
        }
        this.axios
          .delete(services.routes.record + '/selected', { data: { data: { id: ids } } })
          .then((response) => {
            this.cancel();
            this.getRecord();
            this.success = true;
            this.msgSuccess = 'Registros eliminados exitosamente';
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || 'Servicio no disponible';
          })
          .finally(() => {
            this.record.loading = false;
          });
      } else {
        this.record.dialogs.removeSelected = true;
      }
    },
    async exportToExcel() {
      await this.setExcelData();
      // await this.getEmissionsHdc();

      const emissionHdc = this.formatExcelEmissionsHdc(this.excelData);

      const wb = XLSX.utils.book_new();
      const combinedSheet = XLSX.utils.aoa_to_sheet([]);

      // Estilos generales
      const globalFontStyle = {
        font: { name: 'Poppins' }
      };

      const styles = {
        dataTable: {
          header: {
            ...globalFontStyle,
            font: { name: 'Poppins', bold: true, color: { rgb: 'FFFFFF' } },
            fill: { fgColor: { rgb: '4D53F0' } },
            alignment: { horizontal: 'center' }
          },
          data: { ...globalFontStyle, alignment: { horizontal: 'center' } },
          totalRow: {
            ...globalFontStyle,
            font: { ...globalFontStyle, name: 'Poppins', bold: true },
            fill: { fgColor: { rgb: 'C0C2F4' } },
            alignment: { horizontal: 'center' }
          },
          bold: { font: { bold: true }, alignment: { horizontal: 'center' } }
        },
        periodoTable: {
          header: {
            ...globalFontStyle,
            font: { ...globalFontStyle, name: 'Poppins', bold: true, color: { rgb: 'FFFFFF' } },
            fill: { fgColor: { rgb: '4D53F0' } },
            alignment: { horizontal: 'center' }
          },
          data: { ...globalFontStyle, alignment: { horizontal: 'center' } }
        },
        anualTable: {
          header: {
            ...globalFontStyle,
            font: { ...globalFontStyle, name: 'Poppins', bold: true, color: { rgb: 'FFFFFF' } },
            fill: { fgColor: { rgb: '4D53F0' } },
            alignment: { horizontal: 'center' }
          },
          data: { ...globalFontStyle, alignment: { horizontal: 'center' } },
          totalRow: {
            ...globalFontStyle,
            font: { ...globalFontStyle, name: 'Poppins', bold: true },
            fill: { fgColor: { rgb: 'C0C2F4' } },
            alignment: { horizontal: 'center' }
          }
        }
      };

      const applyCellStyle = (sheet, cell, style) => {
        // Verificar si la celda existe en la hoja
        if (!sheet[cell]) return;

        // Inicializar el objeto de estilo si no existe
        sheet[cell].s = sheet[cell].s || {};

        // Combinar el estilo proporcionado con los estilos existentes
        sheet[cell].s = { ...sheet[cell].s, ...style };
      };

      const addTableWithStyles = (sheet, tableData, startRow, title, tableStyles, tableType) => {
        if (title) {
          XLSX.utils.sheet_add_aoa(sheet, [[title]], { origin: { r: startRow, c: 0 } });
          const titleCell = `A${startRow + 1}`;
          applyCellStyle(sheet, titleCell, {
            font: { ...globalFontStyle, bold: true, color: { rgb: '4D53F0' } },
            alignment: { horizontal: 'center' }
          });
          startRow += 1;
        }

        // Insertar datos en la tabla
        XLSX.utils.sheet_add_json(sheet, tableData, { origin: { r: startRow, c: 0 }, skipHeader: false });

        // Establecer la altura de la fila de encabezado (fila `startRow`)
        if (!sheet['!rows']) sheet['!rows'] = [];
        sheet['!rows'][startRow] = { hpx: 40 }; // Establecer la altura de la fila de encabezado a 40px

        // Estilizar encabezados
        const headers = Object.keys(tableData[0]);
        headers.forEach((_, colIndex) => {
          const cell = XLSX.utils.encode_cell({ r: startRow, c: colIndex });
          applyCellStyle(sheet, cell, tableStyles.header);
        });

        // Estilizar filas de datos
        for (let row = 1; row <= tableData.length; row++) {
          headers.forEach((_, colIndex) => {
            const cell = XLSX.utils.encode_cell({ r: startRow + row, c: colIndex });

            // console.log(tableData[row]['Huella de carbono por mes (en tCO2e)']);

            // if (tableData[row - 1]) {
            //   const alcance = tableData[row - 1]['Huella de carbono por mes (en tCO2e)'];
            //   // console.log('alcance', alcance['Huella de carbono por mes (en tCO2e)']);

            //   if (alcance == 'A1' || alcance === 'A2' || alcance === 'A3') {
            //     console.log(alcance);

            //     // let cellhdc = XLSX.utils.encode_cell({ r: startRow + row + 1, c: colIndex });

            //     applyCellStyle(sheet, cell, tableStyles.totalRow);
            //   }
            // }

            // Si es la última fila, aplicar estilo de "Total"
            // if (tableType === 'hdc' && tableData[row]['Huella de carbono por mes (en tCO2e)']) {
            //   applyCellStyle(sheet, cell, tableStyles.totalRow);
            // }

            if (tableType === 'hdc' && row === tableData.length) {
              applyCellStyle(sheet, cell, tableStyles.totalRow);
            } else if (tableType == 'hdc' && row == 1) {
              applyCellStyle(sheet, cell, tableStyles.totalRow);
            } else {
              // Si no es la última fila, aplicar estilo de "Data"
              applyCellStyle(sheet, cell, tableStyles.data);
            }

            if (tableData[row - 1]) {
              const alcance = tableData[row - 1]['Huella de carbono por mes (en tCO2e)'];
              if (alcance == 'A1' || alcance === 'A2' || alcance === 'A3') {
                applyCellStyle(sheet, cell, tableStyles.totalRow);
              }
            }
          });
        }

        return startRow + tableData.length + 2; // Devuelve la siguiente fila disponible
      };
      let currentRow = 0;
      currentRow = addTableWithStyles(combinedSheet, emissionHdc, currentRow, '', styles.dataTable, 'hdc');

      // const ws1 = XLSX.utils.json_to_sheet(emissionHdc);
      combinedSheet['!cols'] = [
        { wch: 55 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 }
      ];
      XLSX.utils.book_append_sheet(wb, combinedSheet, `HDC ${this.hdc.empresa}`);

      // XLSX.utils.book_append_sheet(wb, ws1, 'Consumo de HDC por mes');

      XLSX.writeFile(wb, `Reporte HDC ${this.chartHDC.periodo} de ${this.hdc.empresa}.xlsx`);

      // writeXlsxFile([...data, ...data2], {
      //   schema,
      //   fileName: 'huella_carbono_write_excel_file.xlsx'
      // });
    },

    async getEmissionsHdc() {
      this.chartHDC.loading = true;
      this.record.loading = true;

      await this.axios
        .get(
          services.routes.emissionsHdcReport +
            '?empresa=' +
            this.$store.state.general.company +
            '&fuente_emision=' +
            this.scope.fuente_emision +
            '&tipo_periodo=' +
            this.chartHDC.tipoPeriodo +
            '&periodo=' +
            this.chartHDC.periodo
        )
        .then((response) => {
          this.excelData = response.data.data;
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || 'Servicio no disponible';
        })
        .finally(() => {
          this.record.loading = false;

          this.chartHDC.loading = false;
        });
    },
    async setExcelData() {
      await this.getEmissionsHdc();
      await this.getHDC();
      // setTimeout(() => {
      //   console.log('ESTAMOS EN SETEXE', this.excelData);
      // }, 1000);
    },
    formatExcelEmissionsHdc(data) {
      let result = [];
      result.push({
        'Huella de carbono por mes (en tCO2e)': 'Fuente de emision',
        Enero: 'tCO2e',
        Febrero: 'tCO2e',
        Marzo: 'tCO2e',
        Abril: 'tCO2e',
        Mayo: 'tCO2e',
        Junio: 'tCO2e',
        Julio: 'tCO2e',
        Agosto: 'tCO2e',
        Septiembre: 'tCO2e',
        Octubre: 'tCO2e',
        Noviembre: 'tCO2e',
        Diciembre: 'tCO2e'
      });
      for (const key in data) {
        // const categoryData = data[emission];
        // console.log('CAT', categoryData);
        // if (categoryData[0] == 'alcance 1') {
        //   result.push({ 'Huella de carbono por mes (en tCO2e)': 'A1' });
        // }
        // if (categoryData[0] == 'alcance 2') {
        //   result.push({ 'Huella de carbono por mes (en tCO2e)': 'A2' });
        // }
        // if (categoryData[0] == 'alcance 3') {
        //   result.push({ 'Huella de carbono por mes (en tCO2e)': 'A3' });
        // }

        let alcance;
        let emissionsArray;

        // Determinar el alcance según la clave
        if (key === '0') {
          alcance = 'A1'; // Alcance 1
          emissionsArray = [...data[key]['alcance 1']];
        } else if (key === '1') {
          alcance = 'A2'; // Alcance 2
          emissionsArray = [...data[key]['alcance 2']];
        } else if (key === '2') {
          alcance = 'A3'; // Alcance 3
          emissionsArray = [...data[key]['alcance 3']];
        } else {
          alcance = 'Desconocido'; // Por si aparece una clave inesperada
        }

        // Agregar una fila para el alcance
        if (alcance != 'Desconocido') {
          result.push({
            'Huella de carbono por mes (en tCO2e)': alcance,
            Enero: '',
            Febrero: '',
            Marzo: '',
            Abril: '',
            Mayo: '',
            Junio: '',
            Julio: '',
            Agosto: '',
            Septiembre: '',
            Octubre: '',
            Noviembre: '',
            Diciembre: ''
          });
        }

        // Verificar si hay datos dentro de este alcance
        const categoryData = data[key];
        // console.log('cat', emissionsArray);

        if (Array.isArray(emissionsArray)) {
          emissionsArray.forEach((item) => {
            // console.log('ITEM', item);

            // Agregar elementos específicos al alcance
            result.push({
              'Huella de carbono por mes (en tCO2e)': item?.source || '', // Fuente de emisión
              Enero: item?.data?.Enero || 0,
              Febrero: item?.data?.Febrero || 0,
              Marzo: item?.data?.Marzo || 0,
              Abril: item?.data?.Abril || 0,
              Mayo: item?.data?.Mayo || 0,
              Junio: item?.data?.Junio || 0,
              Julio: item?.data?.Julio || 0,
              Agosto: item?.data?.Agosto || 0,
              Septiembre: item?.data?.Septiembre || 0,
              Octubre: item?.data?.Octubre || 0,
              Noviembre: item?.data?.Noviembre || 0,
              Diciembre: item?.data?.Diciembre || 0
            });
          });
        }

        // result.push({
        //   ""
        // })
      }
      let totals = {
        'Huella de carbono por mes (en tCO2e)': 'Total',
        Enero: 0,
        Febrero: 0,
        Marzo: 0,
        Abril: 0,
        Mayo: 0,
        Junio: 0,
        Julio: 0,
        Agosto: 0,
        Septiembre: 0,
        Octubre: 0,
        Noviembre: 0,
        Diciembre: 0
      };

      result.forEach((row) => {
        if (typeof row.Enero === 'number') {
          totals.Enero += row.Enero;
          totals.Febrero += row.Febrero;
          totals.Marzo += row.Marzo;
          totals.Abril += row.Abril;
          totals.Mayo += row.Mayo;
          totals.Junio += row.Junio;
          totals.Julio += row.Julio;
          totals.Agosto += row.Agosto;
          totals.Septiembre += row.Septiembre;
          totals.Octubre += row.Octubre;
          totals.Noviembre += row.Noviembre;
          totals.Diciembre += row.Diciembre;
        }
      });

      result.push(totals);
      return result;
    },

    async getHDC() {
      try {
        const response = await this.axios.get(
          services.routes.hdc +
            '?empresa=' +
            this.$store.state.general.company +
            '&tipo_periodo=' +
            this.chartHDC.tipoPeriodo +
            '&periodo=' +
            this.chartHDC.periodo
        );
        this.hdc = response.data.data;
        // this.hdc.empresa = 'de ' + this.hdc.empresa;
      } catch (error) {
        this.hdc = {
          empresa: '',
          total: 0,
          alcance1: 0,
          alcance2: 0,
          alcance3: 0,
          subtitulo: 'No existe información de huella de carbono en el año pasado para el mismo periodo'
        };

        this.msgError = error.response.data.message || 'Servicio no disponible';
      } finally {
      }
    }
  }
};
</script>

<style scoped>
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4) !important;
}
.text-normal {
  text-transform: none !important;
}
.horizontal-scroll {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Oculta la barra de desplazamiento en Firefox */
}
.horizontal-scroll::-webkit-scrollbar {
  display: none; /* Oculta la barra de desplazamiento en Safari y Chrome */
}
.v-chip-group.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}
.backgroundChips {
  background-color: #ffde59 !important;
}
.elegido {
  background: #424242 !important;
}
.elegido2 {
  background: #212121 !important;
  color: #cdcdcd;
}
.elegido3 {
  background: #616161 !important;
  color: #e0e0e0;
}
</style>
